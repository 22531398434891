.class-bubble {
  margin: 0px 10px;
  min-width: 4rem;
  max-width: 4rem;
  width: 4rem;
  transition: all 0.3s;
}

.class-bubble:hover {
  transform: scale(1.2);
  overflow: hidden;
  min-width: 7rem;
  max-width: fit-content;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.class-bubble span {
  display: inline-block;
  opacity: 0;
}
.class-bubble:hover span {
  opacity: 1;
  transition: opacity 0.2s;
}
