.add-template {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  border-bottom: 1px solid rgb(223, 230, 236);
}
.add-template button i {
  margin-right: 10px;
}
.add-template button {
  margin-bottom: 10px;
}

.add-a-new-template {
  padding: 0px 20px;
}
.add-a-new-template label {
  display: block;
  margin: 10px 0px;
}
.display-flex-and-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.select-option {
  margin: 10px auto;
}

.time-for-template {
  background-color: rgb(241, 245, 250);
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
