.student-details {
  background-color: rgb(248, 249, 254);
}
.student-info-block {
  position: relative;
  border: solid 2px rgb(50, 50, 93);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: white;
}

#edit-mode-info {
  border: solid 2px rgb(51, 238, 151);
}

.edit-student-absolute-button {
  position: absolute;
  bottom: -25px;
  right: 0px;
}

.delete-student-absolute-button {
  position: absolute;
  bottom: -25px;
  right: 100px;
}

.student-info-block label {
  font-weight: 800;
  /* text-decoration: underline; */
}
.student-info-block input {
  margin-bottom: 15px;
  width: 250px;
  max-width: 90%;
}

label + .MuiInput-formControl {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.student-task-table-wrapper {
  padding: 20px;
  display: inline;
}
