.profile-content {
  padding: 0px 20px;
}

.profile-content .profile-header {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.profile-content .profile-header .profile-role-name {
  font-size: 1.1rem;
  margin: auto;
  font-family: "Times New Roman", Times, serif;
  margin-top: 10px;
}
.profile-content input {
  margin-bottom: 10px;
  font-weight: 600;
  color: rgb(82, 95, 127);
}

.profile-content .profile-email {
  font-weight: 700;
}

/* .modal-backdrop {
  opacity: 0.7 !important;
} */
