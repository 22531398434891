@font-face {
  font-family: "JF Flat Regular";
  src: url("../fonts/JF-Flat-regular.eot");
  src: url("../fonts/JF-Flat-regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/JF-Flat-regular.svg#JF Flat Regular") format("svg"),
    url("../fonts/JF-Flat-regular.woff") format("woff"),
    url("../fonts/JF-Flat-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Tajawal;
  src: url(../fonts/Tajawal-Black.ttf);
  font-weight: 300;
}
@font-face {
  font-family: Tajawal;
  src: url(../fonts/Tajawal-Bold.ttf);
  font-weight: normal;
}
@font-face {
  font-family: Tajawal;
  src: url(../fonts/Tajawal-ExtraBold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: Tajawal;
  src: url(../fonts/Tajawal-Light.ttf);
  font-weight: 100;
}
@font-face {
  font-family: Tajawal;
  src: url(../fonts/Tajawal-ExtraLight.ttf);
  font-weight: lighter;
}
@font-face {
  font-family: Tajawal;
  src: url(../fonts/Tajawal-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-SemiBold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: Comfortaa;
  src: url(../fonts/Comfortaa-Light.ttf);
  font-weight: 100;
}

html,
body {
  font-family: Comfortaa, "JF Flat Regular", Tajawal, Arial, Helvetica,
    sans-serif;
}
