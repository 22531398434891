.routes-side-bar {
  /* position: fixed; */
  overflow-y: auto;
  background-color: rgb(238, 240, 248);
  /* background-image: linear-gradient(
      rgb(224, 224, 224),
      rgb(184, 191, 201),
      rgb(156, 167, 182)
    ); */
  width: 100%;
  padding: 20px 10px;
}

.route-tile {
  position: relative;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 5px 5px;
  padding-bottom: 10px;
  color: rgb(50, 50, 93);
  margin: auto;
  margin-bottom: 15px;
}

.route-tile:hover {
  border-bottom: solid 3px rgb(51, 238, 151);
  padding: 7px 7px;
  padding-bottom: 12px;
  cursor: pointer;
}

#route-tile-selected {
  border-bottom: solid 3px rgb(51, 238, 151);
}

.route-tile p {
  font-size: 0.8rem;
  margin-bottom: -5px;
  word-wrap: break-word;
}
.route-tile p span {
  font-weight: 900;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .route-tile p {
    font-size: 0.7rem;
  }
  .route-tile p span {
    font-size: 0.75rem;
  }
}
