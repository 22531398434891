// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.3.1) components

@import "argon-dashboard/bootstrap/root";
@import "argon-dashboard/bootstrap/reboot";
@import "argon-dashboard/bootstrap/type";
@import "argon-dashboard/bootstrap/images";
@import "argon-dashboard/bootstrap/code";
@import "argon-dashboard/bootstrap/grid";
@import "argon-dashboard/bootstrap/tables";
@import "argon-dashboard/bootstrap/forms";
@import "argon-dashboard/bootstrap/buttons";
@import "argon-dashboard/bootstrap/transitions";
@import "argon-dashboard/bootstrap/dropdown";
@import "argon-dashboard/bootstrap/button-group";
@import "argon-dashboard/bootstrap/input-group";
@import "argon-dashboard/bootstrap/custom-forms";
@import "argon-dashboard/bootstrap/nav";
@import "argon-dashboard/bootstrap/navbar";
@import "argon-dashboard/bootstrap/card";
@import "argon-dashboard/bootstrap/breadcrumb";
@import "argon-dashboard/bootstrap/pagination";
@import "argon-dashboard/bootstrap/badge";
@import "argon-dashboard/bootstrap/jumbotron";
@import "argon-dashboard/bootstrap/alert";
@import "argon-dashboard/bootstrap/progress";
@import "argon-dashboard/bootstrap/media";
@import "argon-dashboard/bootstrap/list-group";
@import "argon-dashboard/bootstrap/close";
@import "argon-dashboard/bootstrap/modal";
@import "argon-dashboard/bootstrap/tooltip";
@import "argon-dashboard/bootstrap/popover";
@import "argon-dashboard/bootstrap/carousel";
@import "argon-dashboard/bootstrap/utilities";
@import "argon-dashboard/bootstrap/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// React Differences
@import "react/react-differences";

// Custom SCSS
@import "font";

.center-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center-div-relative {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.message-box-class {
  z-index: 100001;
}
.picture-initials {
  background-color: #8b93a3;
  color: white;
  border-radius: 50%;
  text-align: center;
  padding: 20px;
  margin: auto;
  letter-spacing: 2px;
}

.lang-switcher {
  position: fixed;
  left: 2rem;
  bottom: 2rem;
}

.lang-switcher * {
  font-weight: 500;
}

.el-table {
  border: 0 !important;

  th,
  &__footer-wrapper thead div,
  &__header-wrapper thead div {
    background-color: #f8f9fe !important;
  }
  &__header {
    width: 100% !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

/* width */
::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(51, 238, 151, 0.5);
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(51, 238, 151);
  width: 10px;
  height: 10px;
}

.el-collapse {
  border: none !important;
  padding-left: 5px !important;
}
.el-collapse-item__header {
  border: none !important;
}
.el-collapse-item__wrap {
  border-bottom: none !important;
}
.el-table::after,
.el-table::before {
  z-index: 0 !important;
}
.fc-timeGridDay-button {
  z-index: 0 !important;
}
.fc-dayGridMonth-button {
  z-index: 0 !important;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 2px 2px -2px rgba(218, 204, 204, 0.2),
    0px 4px 5px 2px rgba(228, 208, 208, 0.14),
    0px 1px 7px 2px rgba(223, 223, 223, 0.12) !important;
}

.MuiTextField-root {
  margin-bottom: 12px !important;
  margin-right: 10px !important;
}
