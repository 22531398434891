.class-bubbles-list {
  width: 100%;
  height: 85px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
  overflow-y: hidden;
}

.popper-class {
  word-wrap: break-word;
  white-space: initial;
}
.class-description p {
  font-size: 0.7rem;
  color: black;
  margin-top: 5px;
  font-weight: 700;
}
