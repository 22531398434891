.search-and-add {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px;
  border-left: none;
}

.add-a-new-account {
  padding: 0px 20px;
}

.add-a-new-account label {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  margin-right: 10px;
}

.show-more-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  cursor: pointer;
}
.show-more-form p {
  font-weight: bold;
}
.roles-select {
  margin-top: 20px;
}
