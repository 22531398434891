.student-info-details p {
  font-size: 0.9rem;
}

.student-info-details p span {
  font-size: 0.9rem;
  font-weight: 800;
}

.student-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.student-info-wrapper img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
