.add-a-new-student {
  padding: 0px 20px;
}

.main-content .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.student-details {
  background-color: rgb(248, 249, 254);
  height: 100%;
  overflow-y: auto;
}

.student-details label {
  display: block;
}
