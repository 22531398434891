.students-side-bar {
  /* position: fixed; */
  overflow-y: auto;
  background-color: rgb(238, 240, 248);
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 15px;
}

.student-tile {
  position: relative;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 10px;
  color: rgb(50, 50, 93);
  margin-bottom: 15px;
  cursor: pointer;
}

.student-tile:hover {
  border-bottom: solid 2px rgb(51, 238, 151);
}

#student-tile-selected {
  border-bottom: solid 5px rgb(51, 238, 151);
}

.student-tile p {
  font-size: 0.8rem;
  margin-bottom: -5px;
  word-wrap: break-word;
}
.student-tile p span {
  font-weight: 900;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .student-tile p {
    font-size: 0.7rem;
  }
  .student-tile p span {
    font-size: 0.75rem;
  }
}
