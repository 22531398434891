.login-wrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  height: 20rem;
  max-width: 90%;
  width: 40rem;
  background-color: white;
  border-radius: 25px;
  padding: 3rem;
  border: solid 3px rgb(23, 43, 77);
  z-index: 1000;
}

.login-wrapper h2 {
  position: absolute;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  top: -5rem;
  left: 0;
}

.login-wrapper input {
  border-radius: 20px;
  display: block;
  margin: auto;
  width: 100%;
  height: 3.5rem;
  padding: 5px 10px;
  border: solid 2px rgb(23, 43, 77);
  font-size: 1.7rem;
  font-weight: bold;
}

#password-field {
  padding-right: 40px;
}

.login-wrapper label {
  display: block;
  color: rgb(23, 43, 77);
  font-weight: bold;
}

.login-wrapper button {
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  width: 10rem;
  border-radius: 30px;
  bottom: -1.5rem;
  transform: translateX(-50%);
}

.login-wrapper button:hover {
  position: absolute;
  transform: translateX(-50%) translateY(-5px) scale(1.1);
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.password-input-wrapper .fas {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.7rem;
  cursor: pointer;
}
