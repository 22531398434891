.route-timeline {
  overflow-y: auto;
}

.route-timeline i {
  margin: 10px;
  font-size: 1.5rem;
}

.add-station-to-route {
  cursor: pointer;
}

.add-station-to-route:hover i {
  margin: 8px;
}

.route-station-options {
  display: flex;
  flex-direction: column;
}

.route-station-options i {
  font-size: 1.2rem;
  color: "red";
}

.route-station-options i:hover {
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(51, 238, 151);
}
.route-station-options .fa-trash:hover {
  color: rgb(255, 129, 129);
}

.station-option {
  padding: 8px;
  border-radius: 5px;
  box-shadow: 1px 3px 5px #888888;
}
.station-option:hover {
  cursor: pointer;
  background-color: rgb(131, 145, 165);
  color: white;
  padding: 9.5px;
}

.selected-route-header {
  width: 80%;
  margin: 10px auto;
  margin-top: 20px;
  text-align: center;
  position: relative;
}
.edit-selected-header {
  position: absolute;
  right: 0px;
  top: 5px;
  padding: 0px;
}

.delete-selected-header {
  position: absolute;
  left: 0px;
  top: 5px;
  padding: 0px;
}
