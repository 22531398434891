.invitation-page {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.invitation-page p {
  font-size: 1.4rem !important;
  font-weight: 700;
}
.invitation-page img {
  background-color: rgb(81, 81, 79);
  padding: 20px;
  border-radius: 20px;
}
.invitation-message {
  margin: 20px auto;
}
.invitation-page label {
  color: rgb(81, 81, 79);
  font-weight: 800;
}
.invitation-page input {
  margin-bottom: 25px;
}
