.trip-calendar {
  padding: 20px;
  background-color: white;
  margin: 30px;
  border-radius: 20px;
}

.from-to-add-trip {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.from-to-add-trip div {
  display: flex;
}
.from-to-add-trip div form {
  margin-right: 20px;
}
.add-a-new-trip {
  padding: 0px 20px;
}
.hello {
  cursor: pointer;
}

#pop-over-event {
  position: absolute !important;
  top: 20px !important;
  right: 20px !important;
}

#pop-over-wrapper {
  position: relative !important;
}

.trip-event-details {
  background-color: rgb(245, 246, 253);
  padding: 10px;
  border-radius: 10px;
}
.trip-event-details p {
  font-size: 0.8rem;
}

.trip-event-details p span {
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 10px;
}

.el-popover {
  z-index: 10001 !important;
}

.fc-event-main {
  cursor: pointer;
  margin: 5px;
}

.trip-event {
  margin: 10px;
}
