.users-table th:not(:nth-child(1)),
.users-table td:not(:nth-child(1)) {
  text-align: center;
}

.table-dropdown-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-dropdown-menu span {
  text-align: center;
  padding: 3px 15px;
  cursor: pointer;
}

.table-dropdown-menu span:hover {
  background-color: rgba(114, 170, 243, 0.2);
}
